import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: false,
  user: null,
})

export const loginRequest = (state = INITIAL_STATE, { username, password }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    user: null
  })

export const loginSuccess = (state = INITIAL_STATE, { user }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    user
  })

export const loginFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    user: null,
    error
  })

export const logoutRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    user: null
  })

/**
 * Handlers
 */

export const HANDLERS = {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,

  [Types.LOGOUT_REQUEST]: logoutRequest,
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer

export const isLogged = loginState =>
  loginState.user !== null

export const loggedToken = loginState => loginState.user ? loginState.user.token : null