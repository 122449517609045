import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import { ConnectedRouter } from 'connected-react-router'
import { configureStore, history } from './store/configureStore'
import RootContainer from './containers/RootContainer'

// Redux store config
const { store, persistor } = configureStore()

export default class App extends Component {
  render() {
    return (
      <PersistGate persistor={persistor}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <RootContainer />
          </ConnectedRouter>
        </Provider>
      </PersistGate>
    )
  }
}
