import io from 'socket.io-client'
import Config from '../config'

let _socket = undefined

const _connect = () => {
  _socket = io(Config.masterSocket, {
    transports: ['websocket']
  })
}

/**
 * Socket
 * 
 */
export const socket = () => {
  if (!_socket) {
    _connect()
  }

  return _socket
}
