import styled from 'styled-components'

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #ecf0f1;
  color: #000;
  border-radius: 5px;
  width: 100%;
  height: 130px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  margin-bottom: 10px;

  .loading {
    position: absolute;
    color: #fff;
    margin-left: 40%;
    margin-top: 40px;
    z-index: 999;
  }

  &:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

  ${({ isLoading }) => isLoading ? `
    &::after {
      content: "";
      position: absolute;
      background-color: rgba(0,0,0,0.6);
      border-radius: 5px;
      width: 80%;
      height: 130px;
      z-index: 998;
    }
  ` : ''}
`
export const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 100%;

  &:after {
    background: #555;
    content: "";
    z-index: 100;
    width: 3px;
    height: 50%;
    margin-left: 10px;
  }
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 5px;
`
export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 100%;
  align-self: flex-end;
`
export const ActionItem = styled.span`
  background:
  linear-gradient(
    to bottom, #2980b9 0%,
    #2980b9 100%
  );
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 4px 4px;
  color: #2980b9;
  font-weight: 900;
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  margin-right: 10px;
  transition: background-size .2s, color .1s;

  &:hover {
    background-size: 4px 50px;
    color: #fff;
  }
`
