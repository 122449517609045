import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

/**
 * States
 */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  connections: null,
  killed: null,
  started: null
})

/**
 * Actions dispatches
 */

export const connectionsRequest = (state = INITIAL_STATE, { name }) =>
  Object.assign({}, state, {
    fetching: true,
    error: null,
    connections: null,
    started: null,
    killed: null
  })

export const connectionsSuccess = (state = INITIAL_STATE, { connections }) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    connections
  })

export const connectionsFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    connections: null,
    error
  })

export const storeStopCoreRequest = (state = INITIAL_STATE, { storeId }) =>
  Object.assign({}, state, {
    fetching: true,
    error: null,
    killed: null,
    started: null,
  })

export const storeStopCoreSuccess = (state = INITIAL_STATE, { killed }) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    killed
  })

export const storeStopCoreFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    killed: null,
    error
  })

export const storeStartCoreRequest = (state = INITIAL_STATE, { storeId }) =>
  Object.assign({}, state, {
    fetching: true,
    error: null,
    started: null,
    killed: null
  })

export const storeStartCoreSuccess = (state = INITIAL_STATE, { started }) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    started
  })

export const storeStartCoreFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    started: null,
    error
  })

/**
 * Handlers
 */

export const HANDLERS = {
  [Types.CONNECTIONS_REQUEST]: connectionsRequest,
  [Types.CONNECTIONS_SUCCESS]: connectionsSuccess,
  [Types.CONNECTIONS_FAILURE]: connectionsFailure,

  [Types.STORE_STOP_CORE_REQUEST]: storeStopCoreRequest,
  [Types.STORE_STOP_CORE_SUCCESS]: storeStopCoreSuccess,
  [Types.STORE_STOP_CORE_FAILURE]: storeStopCoreFailure,

  [Types.STORE_START_CORE_REQUEST]: storeStartCoreRequest,
  [Types.STORE_START_CORE_SUCCESS]: storeStartCoreSuccess,
  [Types.STORE_START_CORE_FAILURE]: storeStartCoreFailure
}

export default createReducer(INITIAL_STATE, HANDLERS)
