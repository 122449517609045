import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Creators } from '../../actions'
import { isLogged } from '../../redux/LoginRedux'
import { LoginContainer, LoginContent, ErrorMessage } from './styles'
import { Input, Button } from '../../styles'

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tried: false,
      inputs: {
        username: '',
        password: ''
      }
    }
  }

  onChangeField = ({ target: { name, value } }) => {
    this.setState(state => ({
      ...state,
      inputs: {
        ...state.inputs,
        [name]: value
      }
    }))
  }

  doLogin = () => {
    const { attemptLogin, fetching } = this.props
    const { inputs } = this.state
    const { username, password } = inputs

    if (username.length > 0 && password.length > 0 && !fetching) {
      this.setState({
        tried: true
      }, () => {
        attemptLogin({
          username,
          password
        })
      })
    }
  }

  render() {
    const { fetching, error, loggedIn } = this.props
    const { inputs, tried } = this.state
    const { username, password } = inputs

    return (
      <LoginContainer>
        {
          loggedIn ? <Redirect from='/login' to='/' /> : null
        }

        <LoginContent error={error && tried}>
          Usuário
          <Input name="username" value={username} onChange={this.onChangeField} />

          <br />

          Senha
          <Input type="password" name="password" value={password} onChange={this.onChangeField} />
          {
            error && tried ? <ErrorMessage>Usuário/senha inválido!</ErrorMessage> : null
          }

          <Button style={{marginTop: 15}} disabled={fetching} onClick={this.doLogin}>
            ENTRAR
          </Button>
        </LoginContent>
      </LoginContainer>
    )
  }
}

const mapStateToProps = ({ login }) => ({
  fetching: login.fetching,
  error: login.error,
  loggedIn: isLogged(login)
})

const mapDispatchToProps = dispatch => ({
  attemptLogin: ({username, password}) => dispatch(Creators.loginRequest(username, password))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
