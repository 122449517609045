import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
  loginRequest: ['username', 'password'],
  loginSuccess: ['user'],
  loginFailure: ['error'],

  logoutRequest: null,

  connectionsRequest: ['name'],
  connectionsSuccess: ['connections'],
  connectionsFailure: ['error'],

  storeLogsRequest: ['storeId'],
  storeLogsSuccess: ['logs'],
  storeLogsFailure: ['error'],

  storeHistoryRequest: ['storeId'],
  storeHistorySuccess: ['history'],
  storeHistoryFailure: ['error'],

  storeProductEntryRequest : ['storeId'],
  storeProductEntrySuccess : ['entrys'],
  storeProductEntryFailure : ['error'],
  

  storeStopCoreRequest: ['storeId'],
  storeStopCoreSuccess: ['killed'],
  storeStopCoreFailure: ['error'],

  storeStartCoreRequest: ['storeId'],
  storeStartCoreSuccess: ['started'],
  storeStartCoreFailure: ['error']
})
