import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Modal from 'react-responsive-modal'
import moment from 'moment-timezone'
import ConnectionCard from '../../components/ConnectionCard'
import LogCard from '../../components/LogCard'
import { unixToHumanDate, diffDateTime } from '../../helpers/DateConversion'
import { Creators } from '../../actions'
import { isLogged } from '../../redux/LoginRedux'
import { socket as SocketConnect } from '../../services/socket'
import { ModalContainer, PrettyTable } from './styles'

class Home extends Component {
  constructor(props) {
    super(props)

    this.updateTimer = undefined

    this.state = {
      showLogs: false,
      showHistory: false,
      autoUpdate: false,
      loadingConnections: true,
      connections: [],
      modal: false
    }
  }

  componentDidMount() {
    const { loggedIn } = this.props

    if (loggedIn) {
      const socket = SocketConnect()

      socket.on('online-connection', (connection) => {
        connection.loading = false

        const { userId } = connection
        let { connections } = this.state

        const index = connections.findIndex(p => p.userId === userId)
        if (index !== -1) {
          connections[index] = connection
        } else {
          connections.push(connection)
        }

        this.setState({
          connections
        })
      })

      socket.on('offline-connection', (connection) => {
        const { userId } = connection
        let { connections } = this.state

        const index = connections.findIndex(p => p.userId === userId)
        if (index !== -1) {
          connections.splice(index, 1)

          this.setState({
            connections
          })
        }
      })
    }

  }

  // componentWillReceiveProps(newProps) {
  //   const { fetchingConnections, connections, killed, started } = newProps
  //   const { loadingConnections, autoUpdate } = this.state
  //   const newState = Object.assign({}, this.state)

  //   // We have received the connection list
  //   /*if (!fetchingConnections && connections !== null && loadingConnections) {
  //     if (connections !== newState.connections) {
  //       newState.connections = connections.map(conn => {
  //         return {
  //           ...conn,
  //           loading: false
  //         }
  //       })

  //       newState.loadingConnections = false
  //     }
  //   }*/

  //   // We have received result of the action request
  //  /* if (!fetchingConnections && (killed !== null || started !== null)) {
  //     this.loadConnections(500)
  //   }

  //   if (this.updateTimer === undefined && !fetchingConnections && autoUpdate) {
  //     this.loadConnections()
  //   }*/

  //   // update state if changed
  //   if (newState !== this.state) {
  //     this.setState(newState)
  //   }
  // }

  loadConnections = (delay = 10000) => {
    const { attemptGetConnections, fetchingConnections } = this.props

    if (this.updateTimer !== undefined) {
      clearTimeout(this.updateTimer)
      this.updateTimer = undefined
    }

    if (!fetchingConnections) {
      this.setState({
        loadingConnections: true
      }, () => {
        this.updateTimer = setTimeout(() => {
          attemptGetConnections()
          this.updateTimer = undefined
        }, delay)
      })
    } else {
      setTimeout(() => {
        this.loadConnections(delay)
      }, 20000)
    }
  }

  onChangeChecker = () => {
    this.setState(state => ({
      ...state,
      autoUpdate: !state.autoUpdate
    }), () => {
      if (this.state.autoUpdate) {
        this.loadConnections()
      }
    })
  }

  _closeLogsModal = () => {
    this.setState({
      showLogs: false,
      showHistory: false,
      modal: false
    })
  }

  _refresh = () => {
    const { fetchingConnections, attemptGetConnections } = this.props

    if (!fetchingConnections) {
      this.setState({
        loadingConnections: true
      }, () => {
        attemptGetConnections()
      })
    }
  }

  _showLogs = (storeId) => {
    const { attemptGetLogs } = this.props

    this.setState({
      showLogs: true,
      modal: true
    }, () => {
      // Get logs
      attemptGetLogs(storeId)
    })
  }
  
  _showHistory = (storeId) => {
    const { attemptGetHistory } = this.props

    this.setState({
      showHistory: true,
      modal: true
    }, () => {
      // Get history
      attemptGetHistory(storeId)
    })
  }

  _storeAction = (storeId, action) => {
    const { attemptStopCore, attemptStartCore } = this.props
    const { connections } = this.state

    if (action === 'stop') {
      const index = connections.findIndex(p => p.store && p.store.store_id === storeId)

      if (index >= 0) {
        connections[index].loading = true
        this.setState({
          connections
        }, () => {
          // Attempt to stop core on client
          attemptStopCore(storeId)
        })
      }
    }

    if (action === 'start') {
      const index = connections.findIndex(p => p.store && p.store.store_id === storeId)

      if (index >= 0) {
        connections[index].loading = true
        this.setState({
          connections
        }, () => {
          // Attempt to stop core on client
          attemptStartCore(storeId)
        })
      }
    }
  }

  _renderConnections = () => {
    const { connections } = this.state

    if (connections) {
      return connections.map((conn, index) => {
        const { store, since, currentStatus, coreVersion, process, loading } = conn
        const { store_id, name } = store

        return <ConnectionCard 
                key={index}
                name={name}
                since={since}
                currentStatus={currentStatus}
                coreVersion={coreVersion}
                process={process}
                loading={loading}
                onRequestLogs={() => this._showLogs(store_id)}
                onRequestHistory={() => this._showHistory(store_id)}
                onRequestAction={(action) => this._storeAction(store_id, action)}
              />
      })
    }
  }

  _renderLogs = () => {
    const { fetchingLogs, logs } = this.props

    if (!fetchingLogs && logs) {
      return logs.map((log, index) => {
        const { file, content } = log

        return <LogCard key={index} file={file} content={content} />
      })
    }
  }

  _renderHistory = () => {
    const { fetchingHistory, history } = this.props

    if (!fetchingHistory && history) {
      return history.map((story, index) => {
        const { status, start_date, end_date, duration, active } = story
        
        let dur = ''
        if (duration) {
          dur = moment.utc(duration).format('HH:mm:ss')
        } else {
          dur = diffDateTime(start_date)
        }

        return (
          <tr key={index}>
            <td><b>{status}</b></td>
            <td>{unixToHumanDate(start_date)}</td>
            <td>{end_date ? unixToHumanDate(end_date) : ''}</td>
            <td>{dur}</td>
            <td style={active ? {color: '#16a085', fontWeight: 900} : {}}>{active ? 'Sim' : 'Não'}</td>
          </tr>
        )
      })
    }
  }

  _renderModal = () => {
    const { showLogs, showHistory } = this.state

    if (showLogs) {
      return (
        <>
          <h1 style={{color: '#000'}}>Logs</h1>
          {this._renderLogs()}
        </>
      )
    }

    if (showHistory) {
      return (
        <>
          <h1 style={{color: '#000'}}>Histórico</h1>
          <PrettyTable>
            <thead>
              <tr>
                <th>Status</th>
                <th>Início</th>
                <th>Fim</th>
                <th>Duração</th>
                <th>Ativo</th>
              </tr>
            </thead>
            <tbody>
              {this._renderHistory()}
            </tbody>
          </PrettyTable>
        </>
      )
    }
  }

  render() {
    const { loggedIn } = this.props
    const { modal } = this.state

    return (
      <div>
        {
          !loggedIn ? <Redirect from='/' to='/login' /> : null
        }

        {this._renderConnections()}

        <Modal open={modal} styles={{modal: {width: '80%'}}} onClose={this._closeLogsModal} center>
          <ModalContainer>
            {this._renderModal()}
          </ModalContainer>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ connection, log, history, login }) => ({
  fetchingConnections: connection.fetching,
  fetchingLogs: log.fetching,
  fetchingHistory: history.fetching,
  connections: connection.connections,
  killed: connection.killed,
  started: connection.started,
  logs: log.logs,
  history: history.history,
  errorLogs: log.error,
  loggedIn: isLogged(login)
})

const mapDispatchToProps = dispatch => ({
  attemptGetConnections: (name = '') => dispatch(Creators.connectionsRequest(name)),
  attemptGetLogs: (storeId) => dispatch(Creators.storeLogsRequest(storeId)),
  attemptGetHistory: (storeId) => dispatch(Creators.storeHistoryRequest(storeId)),
  attemptStopCore: (storeId) => dispatch(Creators.storeStopCoreRequest(storeId)),
  attemptStartCore: (storeId) => dispatch(Creators.storeStartCoreRequest(storeId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
