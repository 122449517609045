import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Creators } from '../../actions'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MoonLoader } from 'react-spinners'
import { isLogged } from '../../redux/LoginRedux'

import { Card, FilterContainer, LoaderWrapper } from './styles'
import moment from 'moment'

class Entry extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showEntry: false,
            loadingEntry: true,
            entrys: [],
            search: '',
            searchField: 'name',
            days: 592,
            dayExpression: '>',
            filtered: []
        }

    }


    componentDidMount(props) {
        const { attemptGetEntrys, fetching, entrys } = this.props
        if (!fetching && entrys === null) {
            attemptGetEntrys()
        } else {

        }

    }

    componentWillReceiveProps(props) {
        const { entrys = [] } = props
        if (entrys) {
            const newEntrys = entrys.filter(value => value !== null)
            this.setState({
                ...this.state,
                entrys: newEntrys,
                filtered: newEntrys
            })
        }
    }


    render() {
        const { entrys, searchField } = this.state

        const { fetching, loggedIn } = this.props

        let filtered = []



        if (!fetching) {
            filtered = entrys.filter(value => {
                if (this.state.search === '') {
                    return value
                }
                if (searchField === 'name') {
                    return value.store.name.toLowerCase().trim().indexOf(this.state.search.toLowerCase().trim()) !== -1
                }
                if (searchField === 'date') {
                    let dataModificado = Math.abs(moment(value.date_modified).diff(moment.now(), 'days'))

                    if (this.state.search === '') return true;

                    if (this.state.dayExpression === '=') {
                        return dataModificado === Number(this.state.search)
                    }
                    if (this.state.dayExpression === '>') {
                        return dataModificado > Number(this.state.search)
                    }
                    if (this.state.dayExpression === '<') {
                        return dataModificado < Number(this.state.search)
                    }
                }
                return value
            })
        }


        return (
            <>
                {
                    !loggedIn ? <Redirect from='/entrys' to='/login' /> : null
                }
                <FilterContainer>
                    <input type="text" onChange={(e) => this.setState({
                        ...this.state,
                        search: e.target.value
                    })} />
                    {
                        this.state.searchField === 'date' ? (
                            <select className="dayExpression" onChange={(e) => {
                                this.setState({
                                    ...this.state,
                                    dayExpression: e.target.value
                                })
                            }}>
                                <option value={'>'}> {'>'} </option>
                                <option value={'<'}> {'<'} </option>
                                <option value="="> = </option>
                            </select>
                        ) : ''
                    }
                    <select onChange={(e) => this.setState({
                        ...this.state,
                        searchField: e.target.value
                    })}>
                        <option value="name">Farmacia</option>
                        <option value="date">Dias sem atualizar</option>
                    </select>
                </FilterContainer>

                {

                }

                {
                    fetching ?
                        <LoaderWrapper>
                            <MoonLoader
                                size={75}
                                color={"#ffffff"}
                            />
                        </LoaderWrapper> : (
                            filtered.map((value, index) => (
                                <Card key={index}>
                                    <div className="pharmacy">
                                        <div className="name">
                                            <h4>{value.store.name}</h4>
                                            <a href={value.store.url} target="_blank" rel="noopener noreferrer"> <FontAwesomeIcon icon={faLink} color="#333" size="1x" /></a>
                                        </div>
                                        <p>{moment(value.date_modified).format('DD/MM/YYYY HH:mm')}</p>
                                        <p>{Math.abs(moment(value.date_modified).diff(moment.now(), 'days'))} dias desde a ultima sincronização </p>
                                    </div>
                                    <div className="product">
                                        <h3>Produto</h3>
                                        <p>{value.product.model}</p>
                                        <p>{value.product.ean}</p>
                                    </div>
                                </Card>
                            ))
                        )
                }

            </>
        )
    }
}

const mapStateToProps = ({ entry,login }) => ({
    fetching: entry.fetching,
    entrys: entry.entrys,
    killed: entry.killed,
    started: entry.started,
    loggedIn: isLogged(login)
})

const mapDispatchToProps = dispatch => ({
    attemptGetEntrys: (storeId) => dispatch(Creators.storeProductEntryRequest(storeId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Entry)
