import styled from 'styled-components'

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 50px;
`
export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 40px;
`
export const PrettyTable = styled.table`
  border-spacing: 1;
  border-collapse: collapse;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;

  * {
    position: relative;
  }

  th {
    font-size: 18px;
    color: #fff;
    line-height: 1.2;
    font-weight: unset;
  }

  td, th {
    padding-left: 8px;
  }

  thead tr {
    height: 60px;
    background: #36304a;
  }

  tbody tr {
    height: 50px;
  }

  tbody tr:last-child {
    border: 0;
  }

  td, th {
    text-align: left;
  }

  td.l, th.l {
    text-align: right;
  }

  td.c, th.c {
    text-align: center;
  }

  td.r, th.r {
    text-align: center;
  }

  tbody tr:nth-child(even) {
    background-color: #f5f5f5;
  }
  
  tbody tr {
    font-size: 15px;
    color: #808080;
    line-height: 1.2;
    font-weight: unset;
  }
  
  tbody tr:hover {
    color: #555555;
    background-color: #f5f5f5;
    cursor: pointer;
  }
`
