import { combineReducers } from 'redux'

// Reducers
import ConnectionRedux from './ConnectionRedux'
import LogsRedux from './LogsRedux'
import HistoryRedux from './HistoryRedux'
import LoginRedux from './LoginRedux'
import EntryRedux from './EntryRedux'

export const reducers = {
  login: LoginRedux,
  connection: ConnectionRedux,
  log: LogsRedux,
  history: HistoryRedux,
  entry : EntryRedux
}

export default combineReducers(reducers)
