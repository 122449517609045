import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CardContainer, InfoContainer, ContentContainer, ActionContainer, Text, ActionItem } from './styles'

export default class LogCard extends Component {
  static propTypes = {
    file: PropTypes.string,
    content: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.state = {
      showContent: false
    }
  }

  _toggleShowContent = () => {
    this.setState(state => ({
      ...state,
      showContent: !state.showContent
    }))
  }

  render() {
    const { file, content } = this.props
    const { showContent } = this.state

    return (
      <CardContainer>
        <InfoContainer>
          <Text style={{marginLeft: 20}} bold>{file}</Text>

          <Text style={{justifySelf: 'flex-end', margin: 'auto'}}>Tamanho: {content.length}</Text>

          <ActionContainer>
            <ActionItem onClick={this._toggleShowContent}>
              {showContent ? 'Esconder' : 'Mostrar'}
            </ActionItem>
          </ActionContainer>
        </InfoContainer>
        {
          showContent ? (
            <ContentContainer>
              {content}
            </ContentContainer>
          ) : null
        }
      </CardContainer>
    )
  }
}
