import { all, takeLatest } from 'redux-saga/effects'
import { Types } from '../actions'
import Api from '../services/api'

// Sagas
import { login } from './LoginSagas'
import { getConnections, storeStopCore, storeStartCore } from './ConnectionSagas'
import { getStoreLogs } from './LogsSagas'
import { getStoreHistory } from './HistorySagas'
import { getEntrys } from './EntrySagas'

export default function * rootSaga() {
  yield all([
    takeLatest(Types.LOGIN_REQUEST, login, Api),
    takeLatest(Types.CONNECTIONS_REQUEST, getConnections, Api),
    takeLatest(Types.STORE_STOP_CORE_REQUEST, storeStopCore, Api),
    takeLatest(Types.STORE_START_CORE_REQUEST, storeStartCore, Api),
    takeLatest(Types.STORE_LOGS_REQUEST, getStoreLogs, Api),
    takeLatest(Types.STORE_HISTORY_REQUEST, getStoreHistory, Api),
    takeLatest(Types.STORE_PRODUCT_ENTRY_REQUEST,getEntrys,Api)
  ])
}
