import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { loggedToken } from '../redux/LoginRedux'

const userToken = ({ login }) => loggedToken(login)

export function * getEntrys(api, storeId ) {
  const token = yield select(userToken)
  api.setHeaders({'Authorization': `Bearer ${token}`})

  const response = yield call(api.getEntrys)
  const { ok, data } = response

  if (ok) {
    yield put(Creators.storeProductEntrySuccess(data))
  } else {
    const { error } = data

    yield put(Creators.storeProductEntryFailure(error))
  }
}
