import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

/**
 * States
 */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  entrys: null,
  killed: null,
  started: null
})

/**
 * Actions dispatches
 */

export const entrysRequest = (state = INITIAL_STATE,{storeId}) =>
  Object.assign({}, state, {
    fetching: true,
    error: null,
    entrys: null,
    started: null,
    killed: null
  })

export const entrysSuccess = (state = INITIAL_STATE, { entrys }) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    entrys
  })

export const entrysFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    entrys: null,
    error
  })

/**
 * Handlers
 */

export const HANDLERS = {
  [Types.STORE_PRODUCT_ENTRY_REQUEST]: entrysRequest,
  [Types.STORE_PRODUCT_ENTRY_SUCCESS]: entrysSuccess,
  [Types.STORE_PRODUCT_ENTRY_FAILURE]: entrysFailure,
}

export default createReducer(INITIAL_STATE, HANDLERS)
