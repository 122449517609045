import styled from 'styled-components'

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border: 1px solid #2c3e50;
  color: #000;
  width: 95%;
  min-height: 50px;
  margin-bottom: 10px;
  transition: height 1s;
`
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
`
export const ContentContainer = styled.code`
  border-top: 1px solid #2c3e50;
  width: 100%;
  height: 500px;
  font-size: 14px;
  white-space: pre-line
  overflow-x: scroll;
  animation: logScaleIn 1s;
`
export const Text = styled.span`
  font-weight: ${({ bold }) => bold ? '900' : '500'}
`
export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 100%;
  align-self: flex-end;
`
export const ActionItem = styled.span`
  color: #2980b9;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
