import moment from 'moment-timezone'

/**
 * Parse unix string to formated date string
 */
export const unixToDate = (input) => {
  const currentDate = moment()
  let date = moment(input)

  if (date.utcOffset() !== currentDate.utcOffset()) {
    date = date.utc(true).utcOffset(currentDate.utcOffset())
  }

  return date.format('DD/MM/YYYY')
}

/**
 * Parse unix to formated datetime string
 */
export const unixToHumanDate = (input, dateFormat = 'DD/MM/YYYY', timeFormat = 'HH:mm:ss') => {
  const currentDate = moment()
  let date = moment(input)

  if (date.utcOffset() !== currentDate.utcOffset()) {
    date = date.utc(true).utcOffset(currentDate.utcOffset())
  }

  if (dateFormat === null || dateFormat === undefined || dateFormat.length === 0) return date.format(timeFormat)
  if (timeFormat === null || timeFormat === undefined || timeFormat.length === 0) return date.format('DD/MM/YYYY')

  return `${date.format('DD/MM/YYYY')} às ${date.format(timeFormat)}`
}

/**
 * Parse unix/date string to duration diff
 */
export const diffDateTime = (input) => {
  const endDate = moment()
  let startDate = moment(input)

  // Apply timezone if needed
  if (endDate.utcOffset() !== startDate.utcOffset()) {
    startDate = startDate.utc(true).utcOffset(endDate.utcOffset())
  }

  const seconds = endDate.diff(startDate, 'seconds')
  const minutes = endDate.diff(startDate, 'minutes')
  const hours = endDate.diff(startDate, 'hours')
  const days = endDate.diff(startDate, 'days')

  if (days > 0) return `Há ${days} ${days > 1 ? 'dias' : 'dia'}`
  if (hours > 0) return `Há ${hours} ${hours > 1 ? 'horas' : 'hora'}`
  if (minutes > 0) return `Há ${minutes} ${minutes > 1 ? 'minutos' : 'minuto'}`
  if (seconds > 0) return `Há ${seconds} ${seconds > 1 ? 'segundos' : 'segundo'}`
  
  return 'Agora mesmo'
}
