import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

/**
 * States
 */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  history: null
})

/**
 * Actions dispatches
 */

export const storeHistoryRequest = (state = INITIAL_STATE, { storeId }) =>
  Object.assign({}, state, {
    fetching: true,
    error: null,
    history: null
  })

export const storeHistorySuccess = (state = INITIAL_STATE, { history }) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    history
  })

export const storeHistoryFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    history: null,
    error
  })

/**
 * Handlers
 */

export const HANDLERS = {
  [Types.STORE_HISTORY_REQUEST]: storeHistoryRequest,
  [Types.STORE_HISTORY_SUCCESS]: storeHistorySuccess,
  [Types.STORE_HISTORY_FAILURE]: storeHistoryFailure
}

export default createReducer(INITIAL_STATE, HANDLERS)
