import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { loggedToken } from '../redux/LoginRedux'

const userToken = ({ login }) => loggedToken(login)

export function * getConnections(api, { name }) {
  const token = yield select(userToken)
  api.setHeaders({'Authorization': `Bearer ${token}`})

  const response = yield call(api.getConnections, { name })
  const { ok, data } = response

  if (ok) {
    const { connections } = data

    yield put(Creators.connectionsSuccess(connections))
  } else {
    const { error } = data

    yield put(Creators.connectionsFailure(error))
  }
}

export function * storeStopCore(api, { storeId }) {
  const token = yield select(userToken)
  api.setHeaders({'Authorization': `Bearer ${token}`})

  const response = yield call(api.stopStoreCore, { storeId })
  const { ok, data } = response

  if (ok) {
    const { killed } = data

    yield put(Creators.storeStopCoreSuccess(killed))
  } else {
    const { error } = data

    yield put(Creators.storeStopCoreFailure(error))
  }
}

export function * storeStartCore(api, { storeId }) {
  const token = yield select(userToken)
  api.setHeaders({'Authorization': `Bearer ${token}`})

  const response = yield call(api.startStoreCore, { storeId })
  const { ok, data } = response

  if (ok) {
    const { started } = data

    yield put(Creators.storeStartCoreSuccess(started))
  } else {
    const { error } = data

    yield put(Creators.storeStartCoreFailure(error))
  }
}
