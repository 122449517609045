import styled from 'styled-components'

export const Card = styled.div`
    display: flex;
    background-color: #ecf0f1;
    color: #000;
    border-radius: 5px;
    width: 100%;
    height: 130px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    margin-bottom: 10px;
    padding:20px;
    box-sizing:border-box;

    *{
        margin:0;
    }

    .product{
        width:30%;
        text-align:center;
        border-right:1px solid #ccc;
        display:flex;
        flex-direction:column;
        justify-content:space-between;
    }

    .pharmacy{
        width:50%;
        text-align:center;
        border-right:1px solid #ccc;
        display:flex;
        flex-direction:column;
        justify-content:space-between;

        .name{
            display:flex;
            width:100%;
            justify-content:center;
            align-items:center;
            
            a{
                margin-left:15px;
            }
        }
    }

`

export const FilterContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-bottom:20px;

    input{
        width:25%;
        height:35px;
        border-radius:5px;
        outline:none;
        border:none;
        margin-right:5px;
        font-size:24px;
        box-sizing:border-box;
        padding:10px;
    }

    select{
        width:20%;
        height:35px;
        border-radius:5px;
        outline:none;
        border:none;
        margin-right:5px;
        font-size:24px;
        box-sizing:border-box;
    }

    .dayExpression{
        width:7%;
    }
`

export const LoaderWrapper = styled.div`
    width : 100%;
    display : flex;
    justify-content : center;
    align-items : center;
`