// Pages
import Home from '../views/Home'
import Login from '../views/Login'
import Entry from '../views/Entrys'

const routes = [
  { path: '/', exact: true, name: "ETL Monitorador", component: Home },
  { path: '/login', exact: true, name: "Autenticação", component: Login },
  { path: '/entrys', exact: true, name: "Ultimos Registros", component: Entry },
]

export default routes
