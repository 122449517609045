import styled from 'styled-components'

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
`

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50%;
  max-height: 50%;
  border: 3px solid ${({ error }) => error ? '#854038' : '#34495e'};
  border-radius: 2px;
  padding: 150px;
`
export const ErrorMessage = styled.span`
  font-size: 14px;
  color: #e67e22;
`
