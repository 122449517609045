import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { Creators } from '../../actions'
import { isLogged } from '../../redux/LoginRedux'
import { AppContainer, HeaderContainer, AppTitle, AppContent, IconButton } from '../../styles'
import MypharmaLogo from '../../assets/images/logo-mypharma.png'

// Routes
import NavRoutes from '../../navigation/NavRoutes'

class RootContainer extends Component {
  doLogout = () => {
    const { attemptLogout } = this.props

    attemptLogout()
  }

  render() {
    const { loggedIn } = this.props

    return (
      <Router>
        <AppContainer>
          <HeaderContainer>
            <img src={MypharmaLogo} alt="logo-mypharma" />

            <AppTitle bold>PIVAL</AppTitle>
            <AppTitle>monitorador</AppTitle>

            {
              loggedIn ? (
                <>
                  <Link style={{ marginLeft: 'auto' }} to="/">Monitorador</Link>
                  <Link style={{ marginLeft: '30px' }} to="/entrys">Ultimos registros</Link>
                </>
              ) : ''
            }

            {
              loggedIn ? (
                <IconButton style={{ marginLeft: 'auto' }} onClick={this.doLogout}>
                  <FontAwesomeIcon icon={faSignOutAlt} color="#fff" size="2x" />
                </IconButton>
              ) : null
            }
          </HeaderContainer>
          <AppContent>

            <Switch>
              {NavRoutes.map((route, index) => {
                const { path, exact, component } = route

                return <Route path={path} exact={exact} key={index} component={component} />
              })}
            </Switch>

          </AppContent>
        </AppContainer>
      </Router>
    )
  }
}

const mapStateToProps = ({ login }) => ({
  loggedIn: isLogged(login)
})

const mapDispatchToProps = dispatch => ({
  attemptLogout: () => dispatch(Creators.logoutRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer)
