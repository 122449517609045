import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

/**
 * States
 */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  logs: null
})

/**
 * Actions dispatches
 */

export const storeLogsRequest = (state = INITIAL_STATE, { storeId }) =>
  Object.assign({}, state, {
    fetching: true,
    error: null,
    logs: null
  })

export const storeLogsSuccess = (state = INITIAL_STATE, { logs }) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    logs
  })

export const storeLogsFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    logs: null,
    error
  })

/**
 * Handlers
 */

export const HANDLERS = {
  [Types.STORE_LOGS_REQUEST]: storeLogsRequest,
  [Types.STORE_LOGS_SUCCESS]: storeLogsSuccess,
  [Types.STORE_LOGS_FAILURE]: storeLogsFailure
}

export default createReducer(INITIAL_STATE, HANDLERS)
