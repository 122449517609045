import { call, put } from 'redux-saga/effects'
import { Creators } from '../actions'

export function * login(api, { username, password }) {
  const response = yield call(api.login, { username, password })
  const { ok, data } = response

  if (ok) {
    let { user } = data

    yield put(Creators.loginSuccess(user))
  } else {
    const { error } = data

    yield put(Creators.loginFailure(error))
  }
}
