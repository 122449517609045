import { create } from 'apisauce'
import Config from '../config'

const api = create({
  baseURL: Config.masterApi
})

// Configuration
const setHeaders = (opts) => api.setHeaders(opts)
const setURL = (url) => api.setBaseURL(url)

// Methods
const login = ({ username, password }) => api.post('v1/login', { username, password })
const getConnections = ({ name }) => api.get('v1/connections', { name })
const getStoreLogs = ({ storeId }) => api.get(`v1/logs/${storeId}`)
const getStoreHistory = ({ storeId }) => api.get(`v1/history/${storeId}`)
const stopStoreCore = ({ storeId }) => api.post(`v1/connections/stop/${storeId}`)
const startStoreCore = ({ storeId }) => api.post(`v1/connections/start/${storeId}`)
const getEntrys = (storeId ) => api.get(`v1/conhistory${storeId ? `/${storeId}` : ''}`)

export default {
  setHeaders,
  setURL,
  getEntrys,
  login,
  getConnections,
  getStoreLogs,
  getStoreHistory,
  stopStoreCore,
  startStoreCore
}
