import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { diffDateTime } from '../../helpers/DateConversion'
import { CardContainer, StatusContainer, ContentContainer, ActionContainer, ActionItem } from './styles'

export default class ConnectionCard extends Component {
  static propTypes = {
    name: PropTypes.string,
    since: PropTypes.number,
    currentStatus: PropTypes.object,
    coreVersion: PropTypes.object,
    process: PropTypes.array,
    loading: PropTypes.bool,
    onRequestLogs: PropTypes.func,
    onRequestHistory: PropTypes.func,
    onRequestAction: PropTypes.func
  }

  static defaultProps = {
    loading: false,
    onRequestLogs: () => {},
    onRequestHistory: () => {},
    onRequestAction: () => {}
  }

  constructor(props) {
    super(props)

    this.updateTimer = undefined

    this.state = {
      since: diffDateTime(props.since)
    }
  }

  componentDidMount() {
    this.updateTimer = setInterval(() => {
      const { since } = this.props
      this.setState({
        since: diffDateTime(since)
      })
    }, 3000)
  }

  componentWillUnmount() {
    if (this.updateTimer) {
      clearInterval(this.updateTimer)
    }
  }

  render() {
    const { name, currentStatus, coreVersion, process, loading, onRequestLogs, onRequestHistory, onRequestAction } = this.props
    const { since } = this.state

    const pid = process && process[0] ? process[0].pid : undefined

    return (
      <CardContainer isLoading={loading}>
        {
          loading ? (
            <div className="loading">
              <FontAwesomeIcon icon={faSyncAlt} color="#fff" size="3x" spin={true} />
            </div>
          ) : null
        }

        <StatusContainer>
          <div style={{textAlign: 'center'}}>
            <b style={{marginRight: 5}}>Status: </b>{currentStatus !== undefined ? currentStatus.status : 'Desconhecido'}
            <br />
            {
              pid ? (
                <>
                  <b style={{marginRight: 5}}>PID: </b>{pid}
                </>
              ) : <b style={{color: '#c0392b'}}>PARADO</b>
            }
          </div>
        </StatusContainer>

        <ContentContainer>
          <h3>{name}</h3>
          <span>Conectado: <b style={{color: '#16a085'}}>{since}</b></span>
          {
            coreVersion.currentVersion > 0 ? (
              <span>Versão: <b style={{color: coreVersion.status === 'up-to-date' ? '#16a085' : '#c0392b'}}>{coreVersion.currentVersion} ({coreVersion.status})</b> - Latest available version: <b>{coreVersion.latestVersion}</b></span>
            ) : null
          }
        </ContentContainer>

        <ActionContainer>
          <ActionItem onClick={onRequestLogs}>Logs</ActionItem>
          <ActionItem onClick={onRequestHistory}>Histórico</ActionItem>
          <ActionItem onClick={() => onRequestAction(pid ? 'stop' : 'start')}>
            {pid ? 'Parar' : 'Iniciar'}
          </ActionItem>
        </ActionContainer>
      </CardContainer>
    )
  }
}
