import styled from 'styled-components'

export const AppContainer = styled.div`
  width: 80%;
  height: 100%;
  margin: auto;
`
export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;

  a{
    text-decoration:none;
    color:#FFFFFF;
    
  }
`
export const AppContent = styled.div`
  background-color: #2c3e50;
  width: 100%;
  min-height: 80vh;
  padding: 20px;
  animation: scaleIn 1s;
`
export const AppTitle = styled.h1`
  font-family: 'Squada One', cursive;
  font-weight: ${({ bold }) => bold ? '900' : '100'};
  font-size: 5em;
  margin-left: 15px;
`
export const Input = styled.input`
  background: #34495e;
  color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  padding: 15px;

  &:focus {
    background: #314559;
  }
`
export const Button = styled.button`
  background: #34495e;
  color: #fff;
  font-size: 18px;
  font-weight: 900;
  border: none;
  outline: none;
  padding: 15px;
  cursor: pointer;

  &:hover {
    background: #3e5770;
  }
`
export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`
