import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { loggedToken } from '../redux/LoginRedux'

const userToken = ({ login }) => loggedToken(login)

export function * getStoreLogs(api, { storeId }) {
  const token = yield select(userToken)
  api.setHeaders({'Authorization': `Bearer ${token}`})

  const response = yield call(api.getStoreLogs, { storeId })
  const { ok, data } = response

  if (ok) {
    const { logs } = data

    yield put(Creators.storeLogsSuccess(logs))
  } else {
    const { error } = data

    yield put(Creators.storeLogsFailure(error))
  }
}
